<template>
  <div>
    <br /><br /><br /><br />

    <div class="row">
      <div class="col-12">
        <LegalDisclaimer />
      </div>
    </div>
  </div>
</template>

<script>
import LegalDisclaimer from "@/components/LegalDisclaimer";

export default {
  name: "legalDisclaimer",
  components: {
    LegalDisclaimer,
  },
};
</script>
<style scoped>
.row {
  padding-right: 50px;
  padding-left: 50px;
}
</style>
