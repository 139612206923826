<template>
  <div class="legalDisclaimer">
    <h1>RISKS AND UNCERTAINTIES</h1>
    <p>
      Prospective purchasers of TAMIL Cryptos should carefully consider and
      evaluate all risks and uncertainties associated with the Developer Team
      and its business and operations, and the TAMIL Cryptos. If any
      of such risks and uncertainties develops into actual events, the business,
      financial condition, results of operations and prospects of the Developer
      Team could be materially and adversely affected. In such cases, you may
      lose all or part of the value of the TAMIL Cryptos.
    </p>

    <p>
      Please read the following risks and warnings before deciding to purchase
      TAMIL Cryptos. It should be noted the following list of risks and warnings
      is not exhaustive. Accordingly, prospective purchasers should not place
      undue reliance on these statements.
    </p>

    <p>
      The Developer Team or the TAMIL Cryptos may be affected by newly
      implemented Regulations
    </p>

    <p>
      crypto token trading is generally unregulated worldwide, but numerous
      regulatory authorities across jurisdictions have been outspoken about
      considering the implementation of regulatory regimes which govern
      crypto token or crypto token markets. The Developer Team or the TAMIL
      Tokens may be affected by newly implemented regulations relating to
      cryptocurrencies or crypto token markets, including having to take
      measures to comply with such regulations, or having to deal with queries,
      notices, requests or enforcement actions by regulatory authorities, which
      may come at a substantial. Further, it is difficult to predict how or
      whether governments or regulatory authorities may implement any changes to
      laws and regulations affecting distributed ledger technology and its
      applications, including the TAMIL Cryptos. The Developer Team may also have
      to cease operations in a jurisdiction that makes it illegal to operate in
      such jurisdiction, or make it commercially unviable or undesirable to
      obtain the necessary regulatory approval(s) to operate in such
      jurisdiction. In scenarios such as the foregoing, the trading price of
      TAMIL Cryptos will be adversely affected or TAMIL Cryptos may cease to be
      traded.
    </p>

    <p>
      There may be risks relating to acts of God, natural disasters, wars,
      terrorist attacks, riots, civil commotions widespread communicable
      diseases and other force majeure events beyond the control of the
      Developer Team
    </p>

    <p>
      The performance of the Developer Team’s activities may be interrupted,
      suspended or delayed due to acts of God, natural disasters, wars,
      terrorist attacks, riots, civil commotions, widespread communicable
      diseases and other force majeure events beyond the control of the
      Developer Team. Such events could also lead to uncertainty in the economic
      outlook of global markets and there is no assurance that such markets will
      not be affected, or that recovery from the global financial crisis would
      continue. In such events, the Developer Team’s business strategies,
      results of operations and prospects may be materially and adversely
      affected. Further, if an outbreak of such infectious or communicable
      diseases occurs in any of the countries in which the Developer Team, the
      developers, data providers or data consumers have operations in the
      future, market sentiment could be adversely affected and this may have a
      negative impact on the TAMIL Cryptos and TAMIL community.
    </p>

    <p>
      There may be unanticipated risks arising from the TAMIL Cryptos
      Cryptographic tokens such as the TAMIL Cryptos are a relatively new and
      dynamic technology. In addition to the risks included hereto, there are
      other risks associated with your purchase, holding and use of the TAMIL
      Tokens, including those that the Developer Team cannot anticipate. Such
      risks may further materialise as unanticipated variations or combinations
      of the risks discussed hereto.
    </p>
  </div>
</template>

<script>
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

export default {
  name: "LegalDisclaimer",
  mounted() {
    this.$nextTick(() => {
      // this load after ODM fully loaded

      $(".legalDisclaimer").fade();
    });
  },
};
</script>
<style scoped>
p {
  text-align: justify;
}
</style>
